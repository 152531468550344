html {
  min-height: 100vh;
  background: #1f1f1f;
  color: white;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App > * {
  max-width: 75%;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#reader {
  width: 250;
}

.header {
  display: flex;
  flex-direction: row;
  background: #181818;
  max-width: 100%;
  align-self: stretch;
}

.header > img {
  width: 4em;
  margin: 0.5em;
}

.header > h1 {
  margin-inline: auto;
}

.codefield {
  margin: 0.5em;
}

button {
  background: #157cc0;
  margin: 1em;
  border: 0px;
  padding: 0.5em;
  padding-inline: 1em;
  color:white;
  border-radius: 1em;
}

.overlay{
  position: absolute;
  min-width: 100vw;
  min-height: 100vh;
  background-color: #000000a0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.agent {
  margin: 2em;
  padding: 2em;
  background-color: #4f4f4f;
  border-radius: 1em;
  display: grid;
  grid-template-columns: 80%, 20%;
}

.agent_col1 {
  grid-column: 1;
  align-self: center;
}

.agent_col2 {
  grid-column: 2;
  overflow: hidden;
  align-self: center;
  max-width: 10em;
  
}

.agent_col2 > img {
  max-width: 100%;
}

.agent_col1 > * > label {
  margin: 1em;
}

.agent_col1 > * > input {
  margin: 1em;
}

.objectTypePanel {
  padding: 1em;
  margin: 1em;
  display: grid;
  grid-template-columns: auto auto;
}

.objectTypeIcon {
  position:relative;
}

.objectTypeIcon > img {
  max-width: 10em;
  margin: 1em;
  align-self: center;
  border-radius: 2em;
}

.objectTypeDetails {
  align-self: center;
  text-align: start;
  display: flex;
  flex-direction: column;
}

.objectTypeDetails > h3 {
  font-size: 2em;
}


.largeAction {
  background: #02e20d;
  margin: 1rem;
  font-size: 5em;
  border: 0px;
  padding: 0.5em;
  padding-inline: 1em;
  color:black;
  border-radius: 1em;
}
.smallAction {
  background: #e21c02;
  margin: 1rem;
  font-size: 3em;
  border: 0px;
  padding: 0.5em;
  padding-inline: 1em;
  color:black;
  border-radius: 1em;
}

.HistoryItemEven, .HistoryItemOdd {
  color: inherit;
  text-decoration: inherit;
  
  display: flex;
  flex-direction: row;
  align-self: stretch;
  min-width: 100%;
}

.HistoryItemEven {
  background-color: #FFFFFF10;
}

.checkinItem, .checkoutItem {

  padding: 0.5em; 
  padding-inline: 1em;
  align-self: center;
  margin-inline: 1em;
  background-color: red;
  border-radius: 1em;

}

.checkinItem {
  background-color: #02e20d;
  color: black;
}

.historyActorPanel {
  flex: 1 1 auto;
  align-self: center;
  margin: 0.5em;
}

.overviewActorPanel {
  align-self: center;
  margin: 0.5em;
}

.overviewActorPanel > h3 {
  margin: 0; margin-bottom: 0.5em;
}

.historyActorPanel > h3 {
  
  margin: 0; margin-bottom: 0.5em;
  
}

.HistoryTimestamp {
  align-self: center;
  margin: 0.5em;
  font-family: monospace;
}

.item_code {
  margin-inline: 1em;
  align-self: center;
}

.objectTypeIconSmall > img {
  max-width: 4em;
  margin: 0.25em;
  align-self: center;
  border-radius: 1em;
}

.lblObjectType {
  align-self: center;
  margin-inline: 1em;
  flex: 1 1 auto;
}



.dropdown-menu {
  flex-direction: column;
  justify-items: right;
  background: #3d3d3d;
  padding: 1em;
  border-radius: 0.5em;
  display: none;
}

.dropdown {
  margin: 1em;
  border-radius: 1em;
  display: flex;
}

.dropdown > button {
  background-color: #2d2d2d;
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  font-weight: 1200;
  font-size: 16pt;
  border-radius: inherit;
  text-align: center;
}


.show {
  display: flex;
}

.dropdown-menu > a {
  color: inherit;
  padding-bottom: 1em;
  text-align: left;
}

.objectTypeIcon {
  position:relative;
  width: 100%;
}

.image_edit_overlay {
  position: absolute;
  top: 1em;
  bottom: 1em;
  left: 1em;
  right: 1em;
  height: calc(100%- 2em);
  width: calc(100%- 2em);
  opacity: 0;
  transition: .3s ease;
  background-color: #000000B0;
  border-radius: 2em;
}

.objectTypeIcon:hover .image_edit_overlay {
  opacity: 1;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  font-size: 25pt;
}